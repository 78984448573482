import React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage } from "gatsby-plugin-image"
import NewsletterCta from "../components/newsletter-cta"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"
import _ from "lodash"
import RecipePreview from "../components/recipe-preview"
import RichText from "../components/rich-text"

const useStyles = makeStyles((theme) => ({
  recipeBody: {
    paddingTop: theme.spacing(4),
  },
  header: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  abstract: {
    "&:first-letter": {
      float: "left",
      fontSize: theme.typography.pxToRem(30),
      height: theme.typography.pxToRem(30),
      lineHeight: theme.typography.pxToRem(30),
      paddingRight: "3px",
    },
  },
  responsiveVideo: {
    position: "relative",
    width: "100%",
    paddingBottom: "56.25%",
    height: 0,
  },
  responsiveVideoIframe: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
}))

const RecipeTemplate = ({ data }) => {
  const classes = useStyles()
  const recipe = data.contentfulRecipe
  const relatedRecipes = data.relatedRecipes.edges
  const recipeMeta = data.recipeMeta

  const valuesFromJSON = (document) => {
    const nonContentNodeTypes = ["heading-1", "heading-2", "heading-3"]
    const content = [].concat(document.content || [])
    if (content.length) {
      return _.flatMapDeep(
        content.filter((c) => !nonContentNodeTypes.includes(c.nodeType)),
        valuesFromJSON
      )
    } else {
      return document.value
    }
  }

  const recipeIngredientJson = (recipe) => {
    const recipeIngredientContentBlocks = (recipe.contentBlocks || []).filter(
      (cb) => cb.textType === "Ingredient"
    )
    return {
      content: recipeIngredientContentBlocks.length
        ? recipeIngredientContentBlocks.map((x) => JSON.parse(x.text.raw))
        : JSON.parse(recipe.ingredients.raw),
    }
  }

  const recipeMethodJson = (recipe) => {
    const recipeInstructionContentBlocks = (recipe.contentBlocks || []).filter(
      (cb) => cb.textType === "Recipe Instruction"
    )
    return {
      content: recipeInstructionContentBlocks.length
        ? recipeInstructionContentBlocks.map((x) => JSON.parse(x.text.raw))
        : JSON.parse(recipe.method.raw),
    }
  }

  const recipeDescriptionJson = (recipe) => {
    const recipeDescriptionContentBlocks = (recipe.contentBlocks || []).filter(
      (cb) => cb.textType === "Description-Abstract"
    )
    if (recipeDescriptionContentBlocks.length) {
      return {
        content: recipeDescriptionContentBlocks.map((x) =>
          JSON.parse(x.text.raw)
        ),
      }
    } else {
      return { value: recipe.abstract.abstract }
    }
  }

  const schemaOrgJSONLD = {
    "@context": "http://schema.org",
    "@type": "Recipe",
    datePublished: recipeMeta.createdAt,
    dateModified: recipeMeta.updatedAt,
    description: []
      .concat(valuesFromJSON(recipeDescriptionJson(recipe)))
      .join(" "),
    image: `http:${recipeMeta.thumbnailImage.file.url}`,
    recipeCategory: "Dessert",
    recipeIngredient: valuesFromJSON(recipeIngredientJson(recipe)),
    name: recipe.title,
    prepTime: "PT15M",
    recipeInstructions: valuesFromJSON(recipeMethodJson(recipe)).join(" "),
  }

  return (
    <Layout location={data.location}>
      <Seo title={recipe.title} description={recipe.abstract.abstract} />
      <div style={{ background: "#fff" }}>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(schemaOrgJSONLD)}
          </script>
        </Helmet>
        <Container maxWidth="md" className={classes.header}>
          <Typography variant="h1" component="h1" gutterBottom align="center">
            {recipe.title}
          </Typography>

          <Typography
            variant="subtitle1"
            component="h2"
            gutterBottom
            align="center"
          >
            {recipe.subtitle}
          </Typography>
        </Container>

        <Container maxWidth="md">
          <GatsbyImage
            image={recipe.heroImage.gatsbyImageData}
            alt={recipe.heroImage.title}
          />
          <div style={{ display: "none" }}>
            <img
              src={data?.placeholder?.publicURL}
              alt={recipe.heroImage.title}
              data-pin-media={`https:${recipe.heroPinterestImage?.file?.url}`}
            />
          </div>
        </Container>

        <Container maxWidth="sm" className={classes.recipeBody}>
          <Box mb={4}>
            <Typography variant="body2" gutterBottom>
              Published: {recipe.createdAt} | Last Updated: {recipe.updatedAt}
            </Typography>
          </Box>
          <Box mb={4}>
            <a
              data-pin-do="buttonBookmark"
              data-pin-tall="true"
              data-pin-lang="en"
              href="https://www.pinterest.com/pin/create/button/"
              style={{ color: "#fff" }}
            >
              Save to Pinterest
            </a>
          </Box>
        </Container>

        <Container maxWidth="md" style={{ padding: 0 }}>
          {(recipe.contentBlocks || []).length &&
            recipe.contentBlocks.map((contentBlock) => {
              switch (contentBlock.__typename) {
                case "ContentfulRecipeTextContentBlock":
                  return (
                    <Container key={contentBlock.id} maxWidth="sm">
                      <RichText richTextJson={contentBlock.text.raw} />
                    </Container>
                  )
                case "ContentfulCbImage":
                  if (contentBlock.multipleImages && contentBlock.multipleImages.length > 0) {
                    return (
                      <>
                        {contentBlock.multipleImages.map((image) => {
                          return (
                            <Container key={image.id} maxWidth="md">
                              <Box mt={4} mb={4}>
                                <GatsbyImage
                                  image={image.gatsbyImageData}
                                  alt={image.title}
                                />
                              </Box>
                            </Container>
                          )
                        })}
                      </>
                    )
                  } else {
                    return (
                      <Container key={contentBlock.id} maxWidth="md">
                        <Box mt={4} mb={4}>
                          <GatsbyImage
                            image={contentBlock.image.gatsbyImageData}
                            alt={contentBlock.image.title}
                          />
                        </Box>
                      </Container>
                    )
                  }
                case "ContentfulCbVideo":
                  return (
                    <Container key={contentBlock.id} maxWidth="md">
                      <Box mb={6} className={classes.responsiveVideo} mt={4}>
                        <iframe
                          className={classes.responsiveVideoIframe}
                          title={contentBlock.title}
                          width="560"
                          height="315"
                          src={`https://www.youtube.com/embed/${contentBlock.youtubeVideoId}`}
                          frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </Box>
                    </Container>
                  )
                case "ContentfulCbHtml":
                  return (
                    <Container key={contentBlock.id} maxWidth="md">
                      <Box mt={4} mb={4}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: contentBlock.html.html,
                          }}
                        />
                      </Box>
                    </Container>
                  )
                default:
                  return null
              }
            })}
        </Container>

        <Container maxWidth="sm">
          {(recipe.contentBlocks || []).length === 0 && (
            <>
              <Box mb={4}>
                <Typography variant="body1" className={classes.abstract}>
                  {recipe.abstract.abstract}
                </Typography>
              </Box>
              {recipe.youtubeVideoID && (
                <Box mb={4} className={classes.responsiveVideo}>
                  <iframe
                    className={classes.responsiveVideoIframe}
                    title={recipe.title}
                    width="560"
                    height="315"
                    src={`https://www.youtube.com/embed/${recipe.youtubeVideoID}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </Box>
              )}
              <Box mb={6}>
                <Typography variant="h3" gutterBottom>
                  Ingredients
                </Typography>
                <RichText richTextJson={recipe.ingredients.raw} />
              </Box>
              <Box mb={6}>
                <Typography variant="h3" gutterBottom>
                  Method
                </Typography>
                <RichText richTextJson={recipe.method.raw} />
              </Box>
              {recipe.specialEquipment && (
                <Box mb={6}>
                  <Typography variant="h3" gutterBottom>
                    Special Equipment
                  </Typography>
                  <RichText richTextJson={recipe.specialEquipment.raw} />
                </Box>
              )}
            </>
          )}
          <NewsletterCta />
          <Box mb={6}>
            <Typography variant="h2" gutterBottom>
              Related Recipes
            </Typography>
            <Grid container spacing={1}>
              {relatedRecipes.map(({ node }) => {
                return (
                  <Grid key={node.id} item xs={12}>
                    <RecipePreview recipe={node} />
                  </Grid>
                )
              })}
            </Grid>
          </Box>
          {false && (
            <>
              <Typography variant="h3" gutterBottom>
                Comments
              </Typography>
              <Typography variant="body1" gutterBottom>
                We are always reading the comments and constantly updating our
                recipes to make sure they contain all the necessary details for
                you to achieve great results! Let us know if you have any
                questions or suggestions for improving the recipe.
              </Typography>
            </>
          )}
        </Container>
      </div>
    </Layout>
  )
}

export default RecipeTemplate

export const pageQuery = graphql`
  query RecipeBySlug($slug: String!, $relatedRecipeSlugs: [String]!) {
    placeholder: file(
      absolutePath: { regex: "/src/images/dessert-white.png/" }
    ) {
      publicURL
    }
    site {
      siteMetadata {
        title
      }
    }
    contentfulRecipe(slug: { eq: $slug }) {
      title
      slug
      subtitle
      youtubeVideoID
      createdAt(formatString: "MMMM Do, YYYY")
      updatedAt(formatString: "MMMM Do, YYYY")
      abstract {
        abstract
      }
      ingredients {
        raw
      }
      method {
        raw
      }
      specialEquipment {
        raw
      }
      heroImage {
        title
        gatsbyImageData
      }
      heroPinterestImage {
        title
        file {
          url
        }
      }
      contentBlocks {
        __typename
        ... on ContentfulCbImage {
          id
          image {
            title
            gatsbyImageData(layout: CONSTRAINED)
          }
          multipleImages {
            title
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
        ... on ContentfulRecipeTextContentBlock {
          id
          textType
          text {
            raw
          }
        }
        ... on ContentfulCbVideo {
          id
          title
          youtubeVideoId
        }
        ... on ContentfulCbHtml {
          id
          html {
            html
          }
        }
      }
    }
    recipeMeta: contentfulRecipe(slug: { eq: $slug }) {
      createdAt(formatString: "YYYY-MM-DD")
      updatedAt(formatString: "YYYY-MM-DD")
      thumbnailImage {
        file {
          url
        }
      }
    }
    relatedRecipes: allContentfulRecipe(
      filter: { slug: { in: $relatedRecipeSlugs } }
    ) {
      edges {
        node {
          id
          title
          slug
          createdAt(formatString: "MMMM Do, YYYY")
          abstract {
            abstract
          }
          thumbnailImage {
            title
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
  }
`
